<template>
  <div class="cart">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">{{
          $t("Goods.Pages.Home")
        }}</my-breadcrumb-item>
        <my-breadcrumb-item to="/" :active="true" :isDisabled="true">{{
          $t("Cart.Pages.MyCart")
        }}</my-breadcrumb-item>
      </my-breadcrumb>

      <product-list v-if="carts.length != 0"></product-list>

      <div class="empty" v-if="carts.length == 0 && !cart.begin">
        <empty
          :decripe="$t('Cart.Pages.CartEmpty')"
          :setStyle="{
            fontSize: '1rem',
            color: '#dc3545',
            fontWeight: '600',
          }"
        >
          <template v-slot:img>
            <div class="icon">
              <i class="iconfont icon-Cart"></i>
            </div>
          </template>
          <template v-slot:button>
            <div class="btn" @click="$router.push('/home/index')">
              {{ $t("Cart.GoHomeBuy") }}
            </div>
          </template>
        </empty>
      </div>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";

import Empty from "common/empty/Empty";
import ProductList from "./child/ProductList";

export default {
  name: "Cart",
  data() {
    return {};
  },

  computed: {

    // 当前的购物车商品列表
    carts() {
        return this.$store.state.cart.cart.carts;
    },

    // 当前购物车总数据
    cart() {
        return this.$store.state.cart.cart;
    },

 
  },

  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
    Empty,
    ProductList,
  },
};
</script>
<style lang='scss' scoped>
.cart {
  padding-top: 1.5rem;
  background-color: white;
  padding-bottom: 2rem;
  min-height: 20rem;
  .container {
    .empty {
      .icon {
        background-color:var(--bg-color-hint);
        display: flex;
        border-radius: 50%;
        i {
          color:var(--red-color);
          font-size: 3.5rem;
          padding: 4rem;
        }
      }

      .btn {
        width: 100%;
        height: 2.8rem;
        border-radius: 1.4rem;
        background-color:var(--main-color);
        color: white;
        font-size: 0.875rem;
        line-height: 2.8rem;
        text-align: center;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
</style>