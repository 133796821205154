<template>
  <div class="cartInfo" ref="cartInfo">
    <def-loading
      :setStyle="{ position: 'fixed' }"
      :isshow="isshow"
    ></def-loading>
    <table class="cartTable" border="0" cellpadding="0" cellspacing="0">
      <thead class="tableHead">
        <tr>
          <th></th>
          <th></th>
          <th>{{ $t("Goods.Pages.ProductName") }}</th>
          <th>{{ $t("Home.Pages.Price") }}</th>
          <th>{{ $t("Goods.Pages.Quant") }}</th>
          <th>{{ $t("User.Orders.Pages.LittleTotal") }}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody class="tableBody" ref="tableBody">
        <tr v-for="(item, index) in carts" :key="item.cart_ident">
          <td>
            <my-checkbox
              v-model="cart_ident"
              @input="changeChoose"
              :label="item.cart_ident"
              :color="{ active: '#6c757d', unactive: '#6c757d' }"
            ></my-checkbox>
          </td>
          <td>
            <img
              @click="goGoodDetil(item.product_id)"
              v-lazy="item.image"
              alt=""
              class="productImg"
            />
          </td>
          <td>
            <p class="productName" @click="goGoodDetil(item.product_id)">
              {{ $cl('name',item,$i18n.locale)}}
            </p>
          </td>
          <td>
            <p class="price">
              {{ item.price_text }}
            </p>
          </td>
          <td>
            <div class="quantity">
              <div class="countNum">
                <my-icon
                  :Iconconfig="Iconconfig"
                  chooseIcon="icon-jianhao"
                  @iconBtnClick="subGoods(item.cart_ident)"
                ></my-icon>
                <input
                  class="inputNum"
                  type="text"
                  ref="my_input"
                  :value="item.quantity"
                  @blur="
                    changeNum(item.cart_ident, $event, $refs.my_input[index])
                  "
                  @keyup.enter="
                    changeNum(item.cart_ident, $event, $refs.my_input[index])
                  "
                />
                <my-icon
                  :Iconconfig="Iconconfig"
                  chooseIcon="icon-add"
                  @iconBtnClick="addGoods(item.cart_ident)"
                ></my-icon>
              </div>
            </div>
          </td>
          <td>
            <p class="productTotal">{{ item.subtotal_text }}</p>
          </td>
          <td>
            <div class="addLike">
              <i
                class="iconfont icon-like"
                @click="addLike(item.goods_id, item)"
                v-if="item.fav_status == '0'"
              ></i>
              <i
                v-else
                class="iconfont icon-like_fill"
                @click="addLike(item.goods_id, item)"
              ></i>
            </div>
          </td>
          <td>
            <div class="delBtn">
              <i
                class="iconfont icon-guanbi"
                @click="delProduct(item.cart_ident)"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 小计 -->
    <div class="subtotal">
      <div class="left">
        <span class="allchoose" @click="chooseAll">
          <i class="iconfont icon-yigouxuan choose" v-if="isAll"></i>
          <i class="iconfont icon-weigouxuan choose" v-else></i>
          {{ $t("Cart.SelectAll") }}
        </span>
        <span class="delChoose" @click="delProduct(null, true)">
          {{ $t("Cart.DelChoose") }}
        </span>
        <span class="removeCart" @click="clearCart">
          {{ $t("Cart.Pages.CartDeleteAll") }}
        </span>
      </div>

      <div class="right">
        <span class="hasChoose"
          >{{ $t("Cart.HaveChoose") }}<strong>{{ isChooseNum }}</strong
          >{{ $t("Cart.Items") }}</span
        >
        <span class="totalPice"
          >{{ $t("Cart.SelectAll") }}：<strong>{{ total_price }}</strong></span
        >
        <div class="closeAccount" @click="goToCheck">
          {{ $t("Home.Button.Checkout") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cart from "@/model/Cart";
import Goods from "@/model/Goods";
import MyIcon from "common/icon/MyIcon";
import MyCheckbox from "common/checkbox/MyCheckbox";
import debounce from "@/common/my_utils/network/debounce.js";
export default {
  data() {
    return {
      Iconconfig: {
        width: "2rem",
        height: "2rem",
        lineHeight: "2rem",
        backgroundColor: "#edeef5",
        color: "#272435",
        fontSize: "0.75rem",
        border: "none",
      },

      //存放已勾选商品的数组
      cart_ident: [],

      //当前的总价
      total_price: 0,

      isshow: false,
    };
  },
  created() {
    this.init();
  },
  props: {

  },

  computed: {
        // 当前的用户是否已经登录了
    isLogin() {
      return !!this.$store.state.user.token;
    },
        // 当前的购物车商品列表
    carts() {
      return this.$store.state.cart.cart.carts;
    },

    // 当前购物车总数据
    cart() {
      return this.$store.state.cart.cart;
    },

    // 已购清单
    selgoods(){
      return this.$store.state.cart.selgoods
    },

    // 是否全选
    isAll() {
      return this.cart_ident.length == this.carts.length;
    },

    // 已勾选几件商品
    isChooseNum() {
      let arr = this.selgoods;
      if (arr.length == 0) {
        return 0;
      } else {
        return arr.reduce((total, val) => {
          total += val.quantity;
          return total;
        }, 0);
      }
    },
  },
  methods: {
    //初始化
    init() {
      this.checkCart_ident();
      this.getTotalPice();
    },

    //查找本地存储的已勾选的商品，将其置入到cart_ident中，并更新vuex中的selgoods,没有置入全部
    checkCart_ident() {
      let selgoods = localStorage.getItem("selgoods");
      if (selgoods && JSON.parse(selgoods).length) {
        JSON.parse(selgoods).forEach((val) => {
          // 通过cart_ident找购物车真实数据里有没有该数据，没有则不添加
          let res = this.carts.find((item) => {
            return item.cart_ident == val.cart_ident;
          });
          if (res) {
            this.cart_ident.push(val.cart_ident);
          }
        });
      } else {
        this.carts.forEach((val) => {
          this.cart_ident.push(val.cart_ident);
        });
      }
      this.$store.commit("setSelgoods", this.cart_ident);
    },

    // 勾选商品
    changeChoose() {
      this.$store.commit("setSelgoods", this.cart_ident);
      this.getTotalPice();
    },

    //点击全选按钮,当前是全选状态清楚所有，不是则选中全部
    chooseAll() {
      if (this.isAll) {
        this.cart_ident = [];
        this.$store.commit("setSelgoods", this.cart_ident);
        this.total_price = "£0";
      } else {
        this.cart_ident = [];
        this.carts.forEach((val) => {
          this.cart_ident.push(val.cart_ident);
        });
        this.$store.commit("setSelgoods", this.cart_ident);
        this.getTotalPice();
      }
    },

    //传入cart_ident获取商品具体明细
    getDetailByident(cart_ident) {
      return this.carts.find((val) => {
        return val.cart_ident == cart_ident;
      });
    },

    // 加商品
    addGoods(cart_ident) {
      let newNum = this.getDetailByident(cart_ident).quantity + 1;
      this.updateProduct(cart_ident, newNum);
    },

    //减商品
    subGoods(cart_ident) {
      let oldNum = this.getDetailByident(cart_ident).quantity;
      if (oldNum <= 1) {
        return;
      } else {
        let newNum = oldNum - 1;
        this.updateProduct(cart_ident, newNum);
      }
    },

    //更新商品数量
    updateProduct(cart_ident, newNum) {
      this.isshow = true;
      Cart.Update({
        data: {
          cart_ident,
          quantity: newNum,
          selgoods: JSON.stringify([]),
        },
        succ: (res, all) => {
          this.$store.commit("updateCartData", res);
          this.$store.commit("setSelgoods", this.cart_ident);
          this.isshow = false;
          this.getTotalPice();
        },

        fail: (res, all) => {
          this.$message("error", all.msg);
          this.isshow = false;
        },

        
      });
    },

    //删除  more标识删除已选商品
    delProduct(cart_ident, more) {
      if (more) {
        this.delCart(this.cart_ident, more);
      } else {
        this.delCart([cart_ident]);
      }
    },

    //删除商品
    delCart(needDel, more) {
      let info = this.$t("Cart.Msg.DeleteCartOne");
      if (more) {
        info = this.$t("Cart.Msg.DeleteCart");
      }
      this.$confirm(
        "danger",
        this.$t("Common.Confirm.Title"),
        info,
        true,
        null,
        this
      ).then((ele) => {
        Cart.Delete({
          data: {
            cart_ident: JSON.stringify(needDel),
            selgoods: JSON.stringify([]),
          },
          succ: (res, all) => {
            //删除所选商品
            if (more) {
              this.cart_ident = [];
              this.$store.commit("setSelgoods", this.cart_ident);
            } else {
              // 判断当前删除的商品是否在已勾选内，是则删除
              this.cart_ident = this.cart_ident.filter((val) => {
                return val != needDel[0];
              });
              this.$store.commit("setSelgoods", this.cart_ident);
            }
            this.$store.commit("updateCartData", res);
            this.getTotalPice();
            ele.close();
          },

          fail: (res, all) => {
            this.$message("error", all.msg);
            ele.close();
          },
          
        });
      });
    },

    //跳转到商品详情页
    goGoodDetil(product_id) {
      this.$router.push({
        path: "/product/index",
        query: {
          product_id,
        },
      });
    },

    //输入完失去焦点，或者按下回车，重新更新商品
    changeNum(cart_ident, e, my_input) {
      // 如果当前输入的值和之前的值一样则不做处理
      if (my_input._value.toString() == my_input.value.toString()) {
        return;
      }
      let newNum = e.target.value;
      // 检查数字是否合法
      if (newNum == "") {
        e.target.value = my_input._value;
        return this.$message("error", this.$t("Common.Msg.InputError"));
      }
      let reg = /[^(0-9)]/;
      if (reg.test(newNum)) {
        e.target.value = my_input._value;
        return this.$message("error", this.$t("Common.Msg.InputError"));
      } else {
        if (newNum <= 0) {
          return;
        }
        this.updateProduct(cart_ident, parseInt(newNum));
      }
    },

    //跳转到收银台
    goToCheck() {
      if (this.cart_ident.length <= 0) {
        this.$message('error','Please select goods')
        return;
      }
      //获取结算页信息
      Cart.Checkout({
        data: {
          selgoods: JSON.stringify(this.selgoods),
          is_fastbuy: "0",
        },
        succ: (res) => {
          this.$router.push({
            path: "/check/index",
          });
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },

    //计算结算的总价
    getTotalPice() {
      // 如果当前没有勾选项
      if (this.cart_ident.length == 0) {
        return (this.total_price = "£0");
      }
      this.isshow = true;
      Cart.Total({
        data: {
          selgoods: JSON.stringify(this.selgoods),
          is_fastbuy: "",
          extend: "",
          uc_id: "", //使用的优惠券id
          use_balance: "", //使用的余额抵扣
          use_point: "", //使用的积分抵扣
        },
        succ: (res) => {
          this.total_price = res.total.total_price_text;
          this.isshow = false;
        },
        fail: (res, all) => {
          this.isshow = false;
        },
        
      });
    },

    //收藏商品
    addLike(goods_id, item) {
      Goods.Goods.Fav({
        data: {
          goods_id,
        },
        succ: (res, all) => {
          item.fav_status = res.fav_status;
          this.$message("success", all.msg);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },

    //清楚购物车
    clearCart() {
      this.$confirm(
        "danger",
        this.$t("Common.Confirm.Title"),
        this.$t("Cart.Msg.DeleteAllCart"),
        true,
        null,
        this
      ).then((ele) => {
        Cart.cartEmpty({
          succ: (res) => {
            this.$store.dispatch("getCart");
            this.getTotalPice();
            this.cart_ident = [];
            this.$store.commit("setSelgoods", this.cart_ident);
            ele.close();
          },
          fail: (res, all) => {
            this.$message("error", all.msg);
            ele.close();
          },
          
        });
      });
    },
  },

  components: {
    MyIcon,
    MyCheckbox,
  },
};
</script>
<style lang='scss' scoped>
.cartInfo {
  margin-top: 1.25rem;
  position: relative;
  .cartTable {
    width: 100%;
    th,
    td {
      padding: 0.75rem;
      border-bottom: 1px solid var(--border-color-default);
    }
    .tableHead {
      tr {
        th {
          font-size: 0.75rem;
          color:var(--text-color-default);
          text-align: left;
        }
      }
    }
    .tableBody {
      tr {
        td {
          font-size: 0.875rem;
          color:var(--text-color-minor);
          .productImg {
            max-width: 4.375rem;
            cursor: pointer;
          }

          .productName {
            cursor: pointer;
            &:hover {
              color: var(--main-color);
            }
          }

          .quantity {
            .countNum {
              display: flex;
              align-items: center;
              .num {
                margin: 0 0.4rem;
              }
            }
          }
        }
      }
    }
  }

  .subtotal {
    margin-top: 1.5rem;
    border: 1px solid var(--border-color-default);
    display: flex;
    justify-content: space-between;
    .left {
        color:var(--text-color-default);
      display: flex;
      align-items: center;
      .allchoose {
        margin: 0 1rem;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }
      .delChoose {
        margin-right: 1.2rem;
        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }
      .removeCart {
        font-weight: 600;
        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      font-size: 1rem;
        color:var(--text-color-default);
      .totalPice {
        margin: 0 0.5rem;
        strong {
          color:var(--red-color);
        }
      }
      .hasChoose {
        margin-right: 0.5rem;
        strong {
          color:var(--red-color);
          margin: 0 0.3rem;
        }
      }
      .closeAccount {
        height: 3.75rem;
        width: 5.75rem;
        background-color:var(--red-color);
        color: white;
        text-align: center;
        line-height: 3.75rem;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.delBtn {
  font-size: 1rem;
  cursor: pointer;
  i:hover {
    background-color: var( --text-color-minor);
    color: white;
  }
}

.addLike {
  font-size: 1.5rem;
  i {
    cursor: pointer;
  }
}

.inputNum {
  border: none;
  width: 2.5rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
  outline: none;
  text-align: center;
}
</style>